import { Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useSettingSection from "./SettingSection.hooks";
const SettingSection = () => {
  const { handleUpdateCache, loading, searchCacheLoading, handleSearchCache } =
    useSettingSection();
  return (
    <Box sx={{ display: "flex", justifyContent: "center", pt: 16 }}>
      <Button
        sx={{ marginRight: "12px", m: 2 }}
        onClick={handleUpdateCache}
        variant="contained"
        color="primary"
        disabled={loading}
      >
        <Typography variant="h6" textAlign={"center"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Update Cache
            {loading && (
              <CircularProgress
                color="info"
                sx={{
                  width: "26px !important",
                  height: "26px !important",
                  px: 2,
                  color: "white",
                }}
              />
            )}{" "}
          </Box>
        </Typography>
      </Button>

      <Button
        sx={{ marginRight: "12px", m: 2 }}
        onClick={handleSearchCache}
        variant="contained"
        color="primary"
        disabled={searchCacheLoading}
      >
        <Typography variant="h6" textAlign={"center"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Search Cache
            {searchCacheLoading && (
              <CircularProgress
                color="info"
                sx={{
                  width: "26px !important",
                  height: "26px !important",
                  px: 2,
                  color: "white",
                }}
              />
            )}{" "}
          </Box>
        </Typography>
      </Button>
    </Box>
  );
};

export default SettingSection;
