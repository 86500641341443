import { useEffect, useState, useRef } from "react";
import { ToastOptions, toast } from "react-toastify";
import Swal from "sweetalert2";
import copy from "clipboard-copy";
import { categories } from "../../../../config/config";
import {
  ARCHIVED_VIDEO_LIMIT,
  changeVideoStatus,
  deletePinVideo,
  deleteVideoData,
  getAllAdminVideos,
  getAllAuthor,
  pinVideo,
  postVideo,
  putTos3,
  searchVideos,
  updateVideo,
} from "../../../../services/video";
interface IVideoState {
  videoList: IVideoList[] | any;
  totalPage: number;
  currentPage: number;
  searchLoading: boolean;
  searchTitle: string;
  openModel: boolean;
  title: string;
  deleteLoading: boolean;
  description: string;
  categories: string[];
  extension: string;
  isVertical: any;
  authorID: string;
  isDeepLink: any;
  sponsoredLink: string;
  authors: [];
  selectedFile: any;
  file: null;
  videoData: null;
  selectedCategory: [];
  paginationLoading: boolean;
  isEdit: boolean;
  editId: string;
  loading: boolean;
  keywords: string;
  keywordList: [];
  seeMoreModal: boolean;
  isFeatured: any;
  videoUrl: string;
  size: null;
  selectedVideos: string[]
}

interface IVideoList {
  author: { id: string; name: string; imageUrl: string };
  author_id: string;
  createdAt: string;
  description: string;
  hasLike: boolean;
  hasSave: boolean;
  id: string;
  isVertical: boolean;
  is_deeplink: boolean;
  is_featured: boolean;
  keywords: string[];
  original_url: string;
  saveCount: string;
  shareCount: string;
  sponsored_link: string;
  status: string;
  thumbnail: string;
  title: string;
  updatedAt: string;
  url: string;
  user: { name: string; image_url: string };
  user_id: string;
  videoLikes: string;
  video_categories: string[];
  views: string;
}

const useVideoSection = () => {
  const [state, setState] = useState<IVideoState>({
    videoList: [],
    totalPage: 0,
    currentPage: 1,
    openModel: false,
    title: "",
    description: "",
    categories: categories,
    extension: "",
    isVertical: null,
    authorID: "",
    isDeepLink: null,
    sponsoredLink: "",
    authors: [],
    selectedFile: "",
    file: null,
    paginationLoading: false,
    videoData: null,
    selectedCategory: [],
    isEdit: false,
    editId: "",
    loading: false,
    keywords: "",
    keywordList: [],
    isFeatured: null,
    deleteLoading: false,
    searchLoading: false,
    searchTitle: "",
    videoUrl: "",
    size: null,
    seeMoreModal: false,
    selectedVideos: []
  });

  // TODO: AFTER UPLOADING AN IMAGE IN THE CODE WE JUST PASS DATA FOR MP4 VIDEOS

  const playerRef = useRef(null);

  function formatFileSizeBytes(bytes: number): string {
    const sizes = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    if (bytes === 0) return "0 bytes";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizeInUnit = bytes / Math.pow(1024, i);
    const formattedSize = new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
    }).format(sizeInUnit);
    return `${formattedSize} ${sizes[i]}`;
  }

  const handleFileChange = async (event: any) => {
    const fileName = event.target.files[0] && event.target.files[0]?.name;
    const fileExtension = event.target.files[0].name.split(".").pop();
    const maxSizeLimit = 250000000;
    const fileSize = formatFileSizeBytes(event.target.files[0]?.size);

    if (event.target.files[0]?.size > maxSizeLimit) {
      setState(
        (currentState: any) =>
        ({
          ...currentState,
          selectedFile: null,
          extension: null,
          videoData: null,
          file: "File size limit exceeded should be less than 250 MB!",
          size: fileSize,
        } as any)
      );
      return;
    }
    setState(
      (currentState: any) =>
      ({
        ...currentState,
        selectedFile: event.target.files[0],
        extension: fileExtension,
        videoData: event.target.files[0],
        file: fileName,
        size: fileSize,
      } as any)
    );
  };

  const clearModalData = () => {
    setState((currentState: any) => ({
      ...currentState,
      editId: "",
      title: "",
      description: "",
      extension: "",
      isVertical: null,
      authorID: "",
      isDeepLink: null,
      sponsoredLink: "",
      selectedCategory: [],
      file: null,
      loading: false,
      isEdit: false,
      keywords: "",
      isFeatured: null,
      selectedFile: "",
    }));
  };

  const setKeyWords = () => {
    setState((currentState: any) => ({
      ...currentState,
      keywordList: state.keywords.split(","),
    }));
  };

  const setModel = () => {
    setState((currentState: any) => ({
      ...currentState,
      openModel: !state.openModel,
    }));
  };

  useEffect(() => {
    const result = getData();
    result.then((data: any) => {
      setState((currentState: any) => ({
        ...currentState,
        videoList: data?.data && data.data?.data?.videos,
        totalPage: data?.data && data.data?.data?.totalPages,
        authors: data?.authorRes && data?.authorRes?.data?.data,
      }));
    });
  }, []);

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    try {
      setState((currentState: any) => ({
        ...currentState,
        paginationLoading: true,
      }));
      let data = await getAllAdminVideos(page, ARCHIVED_VIDEO_LIMIT, 'ARCHIVED');
      setState((currentState: any) => ({
        ...currentState,
        currentPage: page,
        videoList: data?.data?.videos,
        searchTitle: "",
      }));
    } catch (ex) {
      console.log(ex);
    } finally {
      setState((currentState: any) => ({
        ...currentState,
        paginationLoading: false,
      }));
    }
  };

  const reFetchData = async (page: number) => {
    setTimeout(async () => {
      let data = await getAllAdminVideos(page, ARCHIVED_VIDEO_LIMIT, 'ARCHIVED');
      setState((currentState: any) => ({
        ...currentState,
        videoList: data?.data?.videos,
        openModel: false,
      }));
    }, 500);
  };

  const getData = async () => {
    let data = await getAllAdminVideos(state.currentPage, ARCHIVED_VIDEO_LIMIT, 'ARCHIVED');
    let authorRes = await getAllAuthor();
    return {
      data,
      authorRes,
    };
  };

  const renderToast = (type = "success", message: string) => {
    const toastConfig = {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    } as ToastOptions;
    switch (type) {
      case "success":
        toast.success(message, toastConfig);
        break;
      case "error":
        toast.error(message, toastConfig);
        break;
      default:
        toast.info(message, toastConfig);
    }
  };

  const deleteOperation = async (id: string) => {
    try {
      setState((currentState: any) => ({
        ...currentState,
        deleteLoading: true,
      }));
      await deleteVideoData(id).then((data) => {
        reFetchData(state.currentPage);
        renderToast("success", "Video Deleted Successfully");
      });
    } catch (ex) {
      console.log(ex);
    } finally {
      setState((currentState: any) => ({
        ...currentState,
        deleteLoading: false,
      }));
    }
  };

  const deleteVideo = async (id: string) => {
    Swal.fire({
      title: "Do you want to delete this record?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteOperation(id);
      }
    });
  };

  const archiveVideosOperation = async (ids: string[], status: 'COMPLETED' | 'ARCHIVED') => {
    try {
      setState((currentState: any) => ({
        ...currentState,
        deleteLoading: true,
      }));
      await changeVideoStatus(ids, status).then((data) => {
        console.log('video status changed success', status, data);
        reFetchData(state.currentPage);
        if (status == "ARCHIVED") renderToast("success", "Video Archived Successfully");
        if (status == "COMPLETED") renderToast("success", "Video restored Successfully");
      });
    } catch (ex) {
      console.log(ex);
    } finally {
      setState((currentState: any) => ({
        ...currentState,
        deleteLoading: false,
      }));
    }
  };

  const archiveVideos = async (ids: string[], status: 'COMPLETED' | 'ARCHIVED') => {
    Swal.fire({
      title: status == "ARCHIVED" ? "Do you want to archive these videos?" : "Do you want to restore these videos?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        archiveVideosOperation(ids, status);
      }
    });
  };

  const setUserData = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setState((currentState: any) => ({
      ...currentState,
      [field]: event.target.value,
    }));
  };

  const setUserCategory = (event: any) => {
    setState((currentState: any) => ({
      ...currentState,
      selectedCategory: event,
    }));
  };

  const setIsFeatured = (event: any) => {
    setState((currentState: any) => ({
      ...currentState,
      isFeatured: event,
    }));
  };

  const setUserIsVertical = (event: any) => {
    setState((currentState: any) => ({
      ...currentState,
      isVertical: event,
    }));
  };

  const setUserIsDeepLink = (event: any) => {
    setState((currentState: any) => ({
      ...currentState,
      isDeepLink: event,
    }));
  };

  const onModalDataSubmit = async () => {
    try {
      setState((currentState: any) => ({
        ...currentState,
        loading: true,
      }));
      const io = {
        title: state.title,
        description: state.description,
        categories: state.selectedCategory,
        extension: state.extension,
        is_vertical: state.isVertical,
        author_id: state.authorID,
        is_deeplink: state.isDeepLink, //optional
        sponsored_link: state.sponsoredLink, //optional
        keywords: state.keywords.split(","),
        is_featured: state.isFeatured,
      };

      const result = await postVideo(io);
      if (!result) {
        throw new Error("Some error occurred");
      }

      const uriKey = result.data ? result.data.data.signedUrl : null;
      const key = result.data ? result.data.data.key : null;
      const finalResponse = await putTos3(uriKey, key, state.videoData);
      if (finalResponse) {
        setState((currentState: any) => ({
          ...currentState,
          openModel: false,
        }));
        clearModalData();
      }
      renderToast("success", "Video Added Successfully");
      reFetchData(state.currentPage);
    } catch (ex) {
      console.log("from useVedeosection", ex);
    } finally {
      setState((currentState: any) => ({
        ...currentState,
        loading: false,
      }));
    }
  };

  const editVideo = (data: any) => {
    setState((currentState: any) => ({
      ...currentState,
      openModel: true,
      editId: data.id,
      title: data.title,
      description: data.description,
      selectedCategory: data.video_categories,
      isVertical: data && data.isVertical ? data.isVertical : false,
      isFeatured: data && data.is_featured ? data.is_featured : false,
      authorID: data.author_id,
      isDeepLink: data.is_deeplink,
      sponsoredLink: data.sponsored_link,
      isEdit: true,
      keywords: (data.keywords && data.keywords.join(",")) || "",
    }));
  };

  const copyToClipboard = async (data: any) => {
    try {
      await copy(data?.url);
      renderToast("success", "Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const onEdit = async () => {
    try {
      setState((currentState: any) => ({
        ...currentState,
        loading: true,
      }));
      const io: any = {
        id: state.editId,
        title: state.title,
        description: state.description,
        categories: state.selectedCategory,
        author_id: state.authorID,
        is_deeplink: state.isDeepLink,
        is_vertical: state.isVertical,
        keywords: state.keywords.split(","),
        sponsored_link: state.sponsoredLink,
        video_change: state.selectedFile ? true : false,
        is_featured: state.isFeatured,
      };

      if (io.video_change) {
        io.extension = state.extension as any;
      }

      const result = await updateVideo(io);
      if (!result) {
        throw new Error("Some error occurred");
      }

      if (state.selectedFile) {
        console.log(result.data);
        const uriKey = result.data ? result.data.data.signedUrl : null;
        const key = result.data ? result.data.data.key : null;
        const finalResponse = await putTos3(uriKey, key, state.videoData);
        if (finalResponse) {
          console.log(finalResponse);
        }
      }

      clearModalData();
      reFetchData(state.currentPage);
      renderToast("success", "Video Edited Successfully");
    } catch (ex) {
      console.log("from useVedeosection on edit section", ex);
    } finally {
      setState((currentState: any) => ({
        ...currentState,
        loading: false,
      }));
    }
  };

  const handleChangeSearchTitle = (e: any) => {
    setState((currentState: any) => ({
      ...currentState,
      searchTitle: e.target.value,
    }));
  };

  const handleSubmitSearch = async (event: any) => {
    try {
      if (event.key === "Enter") {
        setState((currentState: any) => ({
          ...currentState,
          searchLoading: true,
        }));
        const res = await searchVideos(state.currentPage, state.searchTitle, 'ARCHIVED');
        if (res && res.data) {
          const videoRecords =
            res.data.videos && res.data.videos.length > 0
              ? res.data.videos
              : null;
          setState((currentState: any) => ({
            ...currentState,
            videoList: videoRecords,
            currentPage: res.data.currentPage,
            totalPage: res.data.totalPages,
          }));
        }
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setState((currentState: any) => ({
        ...currentState,
        searchLoading: false,
      }));
    }
  };

  const showData = (data: any) => {
    setState((currentState: any) => ({
      ...currentState,
      seeMoreModal: true,
      title: data.title,
      description: data.description,
      selectedCategory: data.video_categories,
      isVertical: data && data.isVertical ? data.isVertical : false,
      isFeatured: data && data.is_featured ? data.is_featured : false,
      authorID: data.author_id,
      isDeepLink: data && data.is_deeplink ? data.is_deeplink : false,
      sponsoredLink: data.sponsored_link,
      keywords: (data.keywords && data.keywords.join(",")) || "",
      videoUrl: data.url,
    }));
  };

  const closeSeeMoreModal = () => {
    setState((currentState: any) => ({
      ...currentState,
      seeMoreModal: false,
    }));
    clearModalData();
  };

  const pinVideoById = (id: string) => {
    pinVideo({ videoId: id })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          renderToast("success", "Video pinned successfully");
          reFetchData(state.currentPage);
        } else {
          renderToast("error", "Some error occured while pinning the video");
        }
      })
      .catch((e) => {
        renderToast("error", e?.response?.data?.message);
      });
  };

  const unpinVideoById = (id: string) => {
    deletePinVideo(id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          renderToast("error", "Video un-pinned successfully");
          reFetchData(state.currentPage);
        } else {
          renderToast("error", "Some error occured while pinning the video");
        }
      })
      .catch((e) => {
        renderToast("error", e?.response?.data?.message);
      });
  };

  return {
    ...state,
    playerRef,
    handlePageChange,
    editVideo,
    deleteVideo,
    archiveVideos,
    setModel,
    setUserData,
    clearModalData,
    handleFileChange,
    setUserCategory,
    setUserIsVertical,
    onModalDataSubmit,
    onEdit,
    setKeyWords,
    setIsFeatured,
    handleChangeSearchTitle,
    handleSubmitSearch,
    setUserIsDeepLink,
    getData,
    showData,
    closeSeeMoreModal,
    copyToClipboard,
    pinVideoById,
    unpinVideoById,
  };
};

export default useVideoSection;
