import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../../utils/auth";
import { checkUser, setUser } from "../../services/user";
import { IUserCredential } from "../../types/user";
import { toast } from "react-toastify";

interface IAdminLoginPageState {
  email: string;
  password: string;
  loading: boolean;
}

const useAdminLoginPage = () => {
  const [state, setState] = useState<IAdminLoginPageState>({
    email: "",
    password: "",
    loading: false
  });
  
  const navigate = useNavigate();
  
  useEffect(() => {
    if (checkUser()) {
      navigate("/");
    }
  }, []);

  const onLogin = async () => {
    try {
      setState(currentState => ({
        ...currentState,
        loading: true
      }))
      const io: IUserCredential = {
        email: state.email,
        password: state.password,
        client_id: "mobile",
        client_secret: "secretmobile",
      };

      const result = await login(io);
      if (!result) {
        throw new Error("User Not Found");
      }
      setState(currentState => ({
        ...currentState,
        loading: false
      }))

      if(result.code !== 200){
        toast.error( result.data[0].message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }else{
        await setUser(result.data);
        return navigate("/");
      }
    } catch (ex: any) {
      setState(currentState => ({
        ...currentState,
        loading: false
      }))
      toast.error( ex?.response?.data?.data[0]?.message ? ex?.response?.data?.data[0]?.message : "Error - Try Again", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const changeData = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setState((currentState) => ({
      ...currentState,
      [field]: event.target.value,
    }));
  };

  return {
    ...state,
    onLogin,
    changeData,
  };
};
export default useAdminLoginPage;
