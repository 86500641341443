import axios from "axios";
import { IUserCredential } from "../types/user";

export const login = async (data: IUserCredential) => {
  const res = await axios.post(
    "/api1/api/login",
    {
      ...data,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res.data;
};

export const logout = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("tokenData");
};
