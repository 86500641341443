import React from "react";

interface IAppbarStates {
  anchorElUser: null | HTMLElement | any;
}
const useAppBar = () => {
  const [state, setStates] = React.useState<IAppbarStates>({
    anchorElUser: null,
  });

  const openUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setStates((prevState) => ({
      ...prevState,
      anchorElUser: event.currentTarget,
    }));
  };

  const closeUserMenu = () => {
    setStates((prevState) => ({
      ...prevState,
      anchorElUser: null,
    }));
  };

  return {
    ...state,
    openUserMenu,
    closeUserMenu,
  };
};

export default useAppBar;
