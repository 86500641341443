import { Box } from "@mui/material";
import React from "react";
import Appbar from "../Appbar/Appbar";
import useDashboard from "./Dashboard.hooks";
import AppVersionSection from "./components/AppVersionSection/AppVersionSection";
import SettingSection from "./components/SettingsSection/SettingSection";
import VideoSection from "./components/VideoSection/VideoSection";
import PinnedVideoSection from "./components/PinnedVideos/PinnedVideoSection";
import ArchivedVideoSection from "./components/ArchivedVideoSection/VideoSection";

const Dashboard = () => {
  const { setTab, tab, open, setOpen } = useDashboard();

  let content = <VideoSection />;
  switch (tab) {
    case "Pinned Videos":
      content = <PinnedVideoSection />;
      break;
    case "Video":
      content = <VideoSection />;
      break;
    case "Archived Videos":
      content = <ArchivedVideoSection />;
      break;
    case "Setting":
      content = <SettingSection />;
      break;
    case "App Version":
      content = <AppVersionSection />;
      break;
    default:
      break;
  }

  return (
    <React.Fragment>
      <Appbar tab={tab} setTab={setTab} open={open} setOpen={setOpen} />
      <Box
        sx={{
          paddingLeft: open ? "277px" : "0px",
          paddingTop: "64px",
        }}
      >
        {content}
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
