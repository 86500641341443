import { CloudUpload, Visibility, ContentCopy } from "@mui/icons-material";
import ReactHlsPlayer from "react-hls-player";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PushPinIcon from '@mui/icons-material/PushPin';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import usePinnedVideoSection from "./PinnedVideoSection.hooks";
import PositionCellItem from "./PositionCellItem";

const actionButtonStyle = {
  borderRadius: 50,
  minWidth: 30,
  minHeight: 30,
  // backgroundColor: "red",
  padding: 0,
};

const PinnedVideoSection = () => {
  const {
    videoList,
    currentPage,
    isVertical,
    openModel,
    loading,
    isEdit,
    handlePageChange,
    onEdit,
    setModel,
    setUserIsVertical,
    description,
    title,
    categories,
    authorID,
    setUserData,
    authors,
    file,
    handleFileChange,
    setUserCategory,
    onModalDataSubmit,
    selectedCategory,
    keywords,
    isFeatured,
    setIsFeatured,
    deleteLoading,
    sponsoredLink,
    isDeepLink,
    paginationLoading,
    searchTitle,
    handleChangeSearchTitle,
    handleSubmitSearch,
    searchLoading,
    setUserIsDeepLink,
    showData,
    seeMoreModal,
    closeSeeMoreModal,
    videoUrl,
    playerRef,
    size,
    videoData,
    copyToClipboard,
    unpinVideoById,
    reFetchData,
  } = usePinnedVideoSection();
  console.log("Video List###", videoList);

  const ActionItemsList = ({ data }: { data: any }) => {
    console.log("data", data);

    return (
      <Grid
        container
        spacing={4}
        direction={"row"}
        // bgcolor={"#f2f2f2"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingRight={2}
      >
        <Grid item xs={3}>
          <Tooltip title="Unpin">
            <Button
              color="error"
              sx={actionButtonStyle}
              onClick={() => unpinVideoById(data.id)}
            >
              <PushPinIcon style={{ width: 18 }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Copy">
            <Button
              color="success"
              onClick={() => copyToClipboard(data)}
              sx={actionButtonStyle}
              size="small"
            >
              <ContentCopy fontSize={"small"} style={{ width: 16 }} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  console.log("LOAding", loading);

  return (
    <Container maxWidth="xl" sx={{ padding: 8, pt: 1 }}>
      <Dialog
        sx={{
          pointerEvents: loading ? "none" : "auto",
          pt: 8,
        }}
        open={seeMoreModal}
        onClose={closeSeeMoreModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container pt={1}>
            <Grid item xs={12}>
              <ReactHlsPlayer
                src={videoUrl}
                playerRef={playerRef}
                autoPlay={false}
                controls={true}
                width="80%"
                height="auto"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          pointerEvents: loading ? "none" : "auto",
          pt: 8,
        }}
        open={openModel}
        onClose={setModel}
        fullWidth
      >
        <DialogTitle sx={{ marginTop: 3 }}>{"Add Video details"}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={2} pt={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                placeholder="Enter the title"
                value={title}
                onChange={(e: any) => setUserData(e, "title")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Sponsored link"
                placeholder="Enter the sponsored link"
                value={sponsoredLink}
                onChange={(e: any) => setUserData(e, "sponsoredLink")}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={categories}
                value={selectedCategory}
                onChange={(e, value) => {
                  setUserCategory(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    placeholder="Select the categories"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                rows={4}
                label="Keywords"
                placeholder="Type keywords"
                value={keywords || ""}
                onChange={(e: any) => setUserData(e, "keywords")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                rows={4}
                label="Description"
                placeholder="Write the description"
                value={description}
                onChange={(e: any) => setUserData(e, "description")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pr: 2 }}>
                  Is Vertical
                </FormLabel>
                <RadioGroup
                  aria-label="vertical"
                  name="vertical"
                  value={isVertical}
                  onChange={(e: any) => {
                    setUserIsVertical(e.target.value === "true" ? true : false);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pr: 2 }}>
                  Is Featured
                </FormLabel>
                <RadioGroup
                  aria-label="isFeatured"
                  name="isFeatured"
                  value={isFeatured}
                  onChange={(e: any) => {
                    setIsFeatured(e.target.value === "true" ? true : false);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pr: 2 }}>
                  Is Deeplink
                </FormLabel>
                <RadioGroup
                  aria-label="Is Deep link"
                  name="Is Deep link"
                  value={isDeepLink}
                  onChange={(e: any) => {
                    setUserIsDeepLink(e.target.value === "true" ? true : false);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-for-author">Author ID</InputLabel>
                <Select
                  labelId="select-for-author"
                  id="select-for-author"
                  value={authorID}
                  label="Select the author type"
                  onChange={(e: any) => {
                    setUserData(e, "authorID");
                  }}
                >
                  {authors &&
                    authors.map((item: { id: string; name: string }) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div>
                <input
                  accept="video/*"
                  style={{
                    display: "none",
                  }}
                  disabled={loading}
                  id="video-upload"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="video-upload">
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "250px",
                        border: "blue dotted 0.7px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CloudUpload
                        sx={{
                          fontSize: "120px",
                        }}
                      />
                      <Typography variant="h5">Upload Video</Typography>

                      {file && videoData && (
                        <Typography variant="h6" fontWeight={"bold"}>
                          {file}
                        </Typography>
                      )}
                      {file && videoData && (
                        <Typography variant="h6" fontWeight={"bold"}>
                          {`${size}`}
                        </Typography>
                      )}

                      {file && !videoData && (
                        <Typography
                          variant="h6"
                          fontWeight={"bold"}
                          color={"red"}
                        >
                          {file}
                        </Typography>
                      )}
                      {file && !videoData && (
                        <Typography
                          variant="h6"
                          fontWeight={"bold"}
                          color={"red"}
                        >
                          {`${size}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </label>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!isEdit) {
                    onModalDataSubmit();
                  } else {
                    onEdit();
                  }
                }}
              >
                <Typography variant="h6" textAlign={"center"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isEdit ? "Update" : "Submit"}
                    {loading && (
                      <CircularProgress
                        color="info"
                        sx={{
                          width: "26px !important",
                          height: "26px !important",
                          px: 2,
                          color: "white",
                        }}
                      />
                    )}{" "}
                  </Box>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          color: "black",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          py: 2,
        }}
      >
        <TextField
          sx={{
            pr: 2,
            width: "450px",
          }}
          size="small"
          placeholder="Search title here"
          value={searchTitle}
          id="searchText"
          onChange={handleChangeSearchTitle}
          onKeyDown={handleSubmitSearch}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          minHeight: "610px",
        }}
      >
        {videoList === null && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Typography variant="h4" component="h1" align="center">
              No Records Found
            </Typography>
            <LoadingButton
              loading={paginationLoading}
              disabled={paginationLoading}
              variant="contained"
              sx={{ mt: 4 }}
              color="primary"
              onClick={(e: any) => {
                handlePageChange(e, currentPage);
              }}
            >
              Reload
            </LoadingButton>
          </Box>
        )}
        {searchLoading && <LinearProgress />}
        {paginationLoading && <LinearProgress />}
        {deleteLoading && <LinearProgress />}
        {loading && <LinearProgress />}
        {!videoList?.length && (
          <div style={{ paddingTop: 50, textAlign: "center" }}>
            {loading ? 'Loading Videos...': 'No pinned videos available'}
          </div>
        )}
        {videoList && videoList.length > 0 && (
          <Table
            sx={{ minWidth: 650, marginTop: 2, marginBottom: 5 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}></Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Description
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Author Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    GIF
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Position
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {videoList && videoList.length > 0 && (
              <TableBody>
                {videoList &&
                  videoList.map((row: any) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <IconButton
                          aria-label="see more"
                          color="primary"
                          onClick={() => {
                            showData(row);
                          }}
                        >
                          <Visibility />
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{`${row.description?.substr(0, 150)} ${
                        row.description.length > 150 ? "..." : ""
                      }`}</TableCell>
                      <TableCell>
                        {(row.author && row.author.name) || "N/A"}
                      </TableCell>
                      {/* <TableCell>
                        {row.video_categories &&
                          row.video_categories
                            .slice(0, 2)
                            .map((item: any, index: number) => {
                              return (
                                <>
                                  <Typography variant="body1">{`${item}`}</Typography>
                                </>
                              );
                            })}
                        {row.video_categories.length > 2 ? "..." : ""}
                      </TableCell> */}
                      <TableCell
                        style={{
                          width: 120,
                        }}
                      >
                        <CardMedia
                          component="img"
                          image={row.gif}
                          alt={row.name}
                          sx={{
                            width: row.isVertical ? 100 : 120,
                            aspectRatio: row.isVertical ? 0.7 : 1.5,
                            borderRadius: 2,
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <PositionCellItem
                          row={row}
                          totalLength={videoList.length}
                          refetch={reFetchData}
                          currentPage={currentPage}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <ActionItemsList data={row} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        )}
      </TableContainer>
      {/* no need for pagination */}
      {/* {videoList && videoList.length > 0 && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={totalPage}
            page={30}
            onChange={handlePageChange}
            hidePrevButton={false}
            hideNextButton={false}
          />
        </Box>
      )} */}
    </Container>
  );
};
export default PinnedVideoSection;
