import { LoadingButton } from "@mui/lab";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import useAppVersionSection from "./AppVersionSection.hooks";

const AppVersionSection = () => {
  const { onSave, apiVersionList, loading, changeVersion } = useAppVersionSection();
  return (
    <Box p={5}>
      {apiVersionList && apiVersionList.length <= 0 && <LinearProgress />}
      {apiVersionList && apiVersionList.length > 0 && (
        <Grid container maxWidth={"lg"} sx={{ p: 2, pl: 0 }} spacing={2}>
          {apiVersionList.map((data: any, index: number) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <TextField
                fullWidth
                label={data[0]}
                value={data[1].toString() || ""}
                onChange={(event: any) => {
                  changeVersion(event, index);
                }}
              />
            </Grid>
          ))}
          <Grid item xs={12} textAlign={"center"}>
            <LoadingButton
              sx={{
                width: "25%",
                py: 1,
              }}
              loading={loading}
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              <Typography variant="body1">Save</Typography>
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default AppVersionSection;
