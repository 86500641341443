import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const navigate = useNavigate()
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      <Typography variant="h4" component="h1" align="center">
        Page Not Found
      </Typography>
      <Typography variant="body1" align="center">
        The requested page could not be found.
      </Typography>
      <Button variant="contained" sx={{ mt: 4 }} color="primary" onClick={()=>{
        navigate(-1)
      }}>
        Go Back
      </Button>
    </Box>
  );
}
