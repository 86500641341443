import { useState } from "react";
import { toast } from "react-toastify";
import { searchCache, updateCache } from "../../../../services/setting";
interface IUseSettingSection {
  loading: boolean;
  searchCacheLoading: boolean;
}
const useSettingSection = () => {
  const [state, setState] = useState<IUseSettingSection>({
    loading: false,
    searchCacheLoading: false,
  });

  const renderToast = (message: string) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleUpdateCache = async () => {
    try {
      setState((currentState) => ({
        ...currentState,
        loading: true,
      }));

      const res = await updateCache();
      if (res.results) {
        renderToast("Operation successful");
      }

      setState((currentState) => ({
        ...currentState,
        loading: false,
      }));
    } catch (ex) {
      console.log("from setting section", ex);
    }
  };

  const handleSearchCache = async () => {
    try {
      setState((currentState) => ({
        ...currentState,
        searchCacheLoading: true,
      }));

      const res = await searchCache();
      if (res) {
        renderToast("Operation successful");
      }

      setState((currentState) => ({
        ...currentState,
        searchCacheLoading: false,
      }));
    } catch (ex) {
      console.log("from Cache section", ex);
    }
  };

  return {
    ...state,
    handleUpdateCache,
    handleSearchCache,
  };
};

export default useSettingSection;
