import { CheckCircleOutlined } from "@mui/icons-material";
import { Button, Grid, Input, Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import usePinnedVideoSection from "./PinnedVideoSection.hooks";

const PositionCellItem = ({
  row,
  totalLength = 30,
  refetch,
  currentPage,
}: {
  row: any;
  totalLength: number;
  refetch: (arg: any) => void;
  currentPage: number
}) => {
  const positionRef = useRef<HTMLInputElement>(null);
  const { updateVideoPosition } = usePinnedVideoSection();

  useEffect(() => {
    if (positionRef.current) {
        console.log(row.pinPosition);
      positionRef.current.value = row.pinPosition;
    }
  }, [row.pinPosition]);



  return (
    <Grid container alignItems="center" key={row.id}>
      <Grid item xs={8}>
        <Input
          type="number"
          inputRef={positionRef}
          defaultValue={row.pinPosition}
          inputProps={{ min: 1, max: totalLength }}
          sx={{ minWidth: 40 }}
        />
      </Grid>
      <Grid item xs={4}>
        <Tooltip title="Update position">
          <Button
            sx={{ height: 20, minWidth: "auto" }}
            onClick={() => {
              if (positionRef.current) {
                const newPosition = parseInt(positionRef.current.value, 10);
                if (!isNaN(newPosition)) {
                  updateVideoPosition({
                    videoId: row.id,
                    newPosition: newPosition,
                  });
                  refetch(currentPage);
                  // Clear the input field after updating
                  positionRef.current.value = row.pinPosition;
                } else {
                  console.error("Invalid number");
                }
              }
            }}
          >
            <CheckCircleOutlined />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default PositionCellItem;
