import { createTheme } from "@mui/material";

const mainTheme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default mainTheme;
