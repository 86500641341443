import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getAppVersionInfo,
  updateApiVersionInfo,
} from "../../../../services/appVersion";

interface IAppVersionState {
  apiVersionList: any;
  loading: boolean;
}

const useAppVersionSection = () => {
  const [state, setState] = useState<IAppVersionState>({
    apiVersionList: [],
    loading: false
  });

  const getAllData = async () => {
    try {
      const res = await getAppVersionInfo();
      let arr = Object.entries(res[0]);
      setState((currentState: any) => ({
        ...currentState,
        apiVersionList: arr,
      }));
    } catch (ex) {
      console.log(ex, "from api version ");
    }
  };

  const renderToast = (message: string) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const onSave = async () => {
    try {
      setState(currentState => ({
        ...currentState,
        loading: true
      }))
      let io: any = [];
      state.apiVersionList.forEach((item: any) => {
        let obj = {
          key: item[0],
          value: item[1],
        };

        io.push(obj);
      });

      const result: any = {};
      for (const item of io) {
        if (item.key) {
          result[item.key] = item.value;
        }
      }

      const res = await updateApiVersionInfo(result);
      setState(currentState => ({
        ...currentState,
        loading: false
      }))
      if (res) {
        renderToast("Version are updated successfully");
        getAllData();
      }
    } catch (ex) {
      console.log("from api version section", ex);
    }
  };

  const changeVersion = (event: any, index: number) => {
    console.log(event.target.value);
    state.apiVersionList[index][1] = event.target.value !== '' ? parseInt(event.target.value) : ''
    setState((currentState: any) => ({
      ...currentState,
      apiVersionList: state.apiVersionList
    }));
  };

  useEffect(() => {
    getAllData();
  }, []);

  return {
    ...state,
    onSave,
    changeVersion
  };
};

export default useAppVersionSection;
