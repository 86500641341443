import axios from "axios";
import { getToken } from "./user";
import { logout } from "../utils/auth";
import { toast } from "react-toastify";

export const VIDEO_LIMIT = 20;
export const ARCHIVED_VIDEO_LIMIT = 20;

export const getAllAdminVideos = async (curPage: number, limit?: number, status?: string) => {
  try {
    if (status == 'ARCHIVED') {
      return await axios.get(
        `/api1/api/getAllAdminVideos?skip=${(limit || VIDEO_LIMIT) * (curPage - 1)}&limit=${limit || VIDEO_LIMIT}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${getToken().access_token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    }
    else return await axios.get(
      `/api1/api/getAllAdminVideos?skip=${(limit || ARCHIVED_VIDEO_LIMIT) * (curPage - 1)}&limit=${limit || ARCHIVED_VIDEO_LIMIT}`,
      {
        headers: {
          Authorization: `Bearer ${getToken().access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error: any) {
    toast.error("Unauthorized, user not authorized to access CMS", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      logout();
      window.location.reload();
    }, 2500);
  }
};

export const getAllAuthor = async () => {
  return await axios.get(`/api1/api/getAllAuthors`, {
    headers: {
      Authorization: `Bearer ${getToken().access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const postVideo = async (data: any) => {
  return await axios.post(
    `/api1/api/postVideoDetails`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateVideo = async (data: any) => {
  return await axios.put(
    `/api1/api/updateVideoDetails`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const putTos3 = async (url: any, key: any, binaryVideo: any) => {
  let data = binaryVideo;

  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: url,
    headers: {
      "Content-Type": "video/mp4",
    },
    data: data,
  };

  return await axios
    .request(config)
    .then((response) => {
      const condition = "COMPLETED";
      axios
        .post("/api1/webhooks/webhooks/updateVideoStatus", {
          key,
          condition,
        })
        .then(function (response) {
          console.log(response.status);
        })
        .catch(function (error) {
          console.log(error);
        });
      return true;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteVideoData = async (id: string) => {
  try {
    return await axios.delete(`/api1/api/deleteVideo?video_id=${id}`, {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
      },
    });
  } catch (error) {
    toast.error("Unauthorized, user not authorized to access CMS", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      logout();
      window.location.reload();
    }, 2500);
  }
};

export const changeVideoStatus = async (ids: string[], status: 'COMPLETED' | 'ARCHIVED') => {
  try {
    return await axios.post(`/api1/api/changeVideoStatus`,
      { video_ids: ids, status: status },
      {
        headers: {
          Authorization: `Bearer ${getToken().access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
  } catch (error) {
    // console.log("some error", error)
    // toast.error("Unauthorized, user not authorized to access CMS", {
    //   position: "bottom-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "light",
    // });
    // setTimeout(() => {
    //   logout();
    //   window.location.reload();
    // }, 2500);
  }
};

export const searchVideos = async (curPage: number, searchTitle: string, status?: string) => {
  if (status == 'ARCHIVED') {
    return await axios.get(
      `/api1/api/getAllAdminVideos?skip=${ARCHIVED_VIDEO_LIMIT * (curPage - 1)
      }&limit=${ARCHIVED_VIDEO_LIMIT}&title=${searchTitle}&status=${status}`,
      {
        headers: {
          Authorization: `Bearer ${getToken().access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  }
  else return await axios.get(
    `/api1/api/getAllAdminVideos?skip=${VIDEO_LIMIT * (curPage - 1)
    }&limit=${VIDEO_LIMIT}&title=${searchTitle}`,
    {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const searchArchivedVideos = async (curPage: number, searchTitle: string) => {
  return await axios.get(
    `/api1/api/getAllAdminVideos?skip=${20 * (curPage - 1)
    }&limit=${VIDEO_LIMIT}&title=${searchTitle}`,
    {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const pinVideo = async (data: { videoId: string }) => {
  return await axios.post(
    `/api1/api/pinVideo`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updatePinVideoPosition = async (data: {
  videoId: string;
  newPosition: number;
}) => {
  return await axios.put(
    `/api1/api/updatePinVideoPosition`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const deletePinVideo = async (videoId: string) => {
  return await axios.delete(`/api1/api/deletePinVideo?videoId=${videoId}`, {
    headers: {
      Authorization: `Bearer ${getToken().access_token}`,
    },
  });
};


export const getAllPinnedVideos = async () => {
  try {
    return await axios.get(
      `/api1/api/getPinnedVideos`,
      {
        headers: {
          Authorization: `Bearer ${getToken().access_token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
  } catch (error: any) {
    toast.error("Unauthorized, user not authorized to access CMS", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      logout();
      window.location.reload();
    }, 2500);
  }
};