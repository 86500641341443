import { Menu, MenuItem, Typography } from "@mui/material";
import useUserMenu from "./UserMenu.hook";

interface IUserMenuProps {
  openUserMenu: (event: React.MouseEvent<HTMLElement>) => void;
  closeUserMenu: () => void;
  anchorElUser: null | HTMLElement | any;
}

const UserMenu = (props: IUserMenuProps) => {
  const { logoutUser } = useUserMenu();

  return (
    <>
      <Menu
        sx={{ mt: "45px", position: "absolute", zIndex: "55555555" }}
        id="menu-appbar"
        anchorEl={props.anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(props.anchorElUser)}
        onClose={props.closeUserMenu}
      >
        {/* <MenuItem onClick={props.closeUserMenu}>
          <Typography textAlign="center">{"Profile"}</Typography>
        </MenuItem> */}
        <MenuItem className="userMenuone" onClick={logoutUser}>
          <Typography textAlign="center">{"Logout"}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
