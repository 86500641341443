import { logout } from "../../../../utils/auth";
import { useNavigate } from "react-router-dom";

const useUserMenu = () => {
  const navigate = useNavigate();

  const logoutUser = () => {
    logout();
    navigate("/login");
  };

  return {
    logoutUser
  };
};

export default useUserMenu;
