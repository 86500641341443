import dayjs from 'dayjs'
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/customParseFormat'))

export const setUser = (userData: any) => {
    localStorage.setItem("userData", JSON.stringify(userData.user));
    localStorage.setItem("tokenData", JSON.stringify({
        access_token: userData.access_token,
        refresh_token: userData.refresh_token,
        expires: userData.expires
    }));
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem("userData") || "");
}

export const getToken = () => {
  return JSON.parse(localStorage.getItem("tokenData") || "");
};

export const checkUser = () => {
    //TODO: convert date to utc
    if (localStorage.getItem("tokenData") && localStorage.getItem("userData")) {
        let tokenData = JSON.parse(localStorage.getItem("tokenData") || "");
        const dt = dayjs(tokenData.expires, "YYYY-MM-DDTHH:mm:ss[Z]").format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const currentDate = dayjs();
        const formattedDate = currentDate.format("YYYY-MM-DD HH:mm:ss");
        return dt > formattedDate;
    }
    return false;
}