import axios from "axios";
import { getToken } from "./user";
import { toast } from "react-toastify";
import { logout } from "../utils/auth";

export const getAppVersionInfo = async () => {
  const res = await axios.get(`/api1/api/getAppVersionInfo`, {
    headers: {
      Authorization: `Bearer ${getToken().access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return res.data.data;
};

export const updateApiVersionInfo = async (data: any) => {
  try {
    const res = await axios.put(`/api1/api/putAppVersionInfo`, data, {
      headers: {
        Authorization: `Bearer ${getToken().access_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return res.data.data;
  } catch (error: any) {
    toast.error("Unauthorized, user not authorized to access CMS", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      logout();
      window.location.reload();
    }, 2500);
  }
};
