export const categories = [
  "Labour + Birth",
  "Pregnancy Health + Wellness",
  "Pregnancy Signs + Symptoms",
  "Preparing for baby",
  "Your Bump",
  "Child health + development",
  "Child sleep + settling",
  "Food + Nutrition",
  "Connection",
  "Play + Learning",
  "School kids",
  "Tweens",
  "Health + Wellness",
  "Home + Lifestyle",
  "Holidays + Travel",
  "Parenthood",
  "Real Stories",
];
