import { Navigate } from "react-router-dom";
import { checkUser } from "../../services/user";

const Authenticate = ({ children }: any) => {
  const user = checkUser();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
};
export default Authenticate;
