import { Menu } from "@mui/icons-material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import SideBar from "../Dashboard/components/SideBar/SideBar";
import useAppBar from "./Appbar.hooks";
import UserMenu from "./components/UserMenu/UserMenu";
import { getUser } from "../../services/user";

function Appbar({ tab, setTab, open, setOpen }: any) {
  const { openUserMenu, anchorElUser, closeUserMenu } = useAppBar();
  let user = getUser();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: "999999",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setOpen();
            }}
          >
            <Menu />
          </IconButton>

          <Box sx={{ flexGrow: 1, fontFamily: "sans-serif", fontSize: "22px" }}>
            Kiindred
          </Box>
          <Box>
            <Tooltip title="User Menu">
              <IconButton onClick={openUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user.name} src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <UserMenu
              anchorElUser={anchorElUser}
              openUserMenu={openUserMenu}
              closeUserMenu={closeUserMenu}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <SideBar open={open} tab={tab} setTab={setTab} />
    </>
  );
}
export default Appbar;
