import { Route, Routes } from "react-router-dom";
import AdminLoginPage from "./components/AdminLogin/AdminLoginPage";
import Authenticate from "./components/Authenticate/Authenticate";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFoundPage from "./components/NotFound/PageNotFound";

export default function Main() {
  return (
    <Routes>
      <Route path="/login" element={<AdminLoginPage />} />
      <Route
        path="/"
        element={
          <Authenticate>
            <Dashboard />
          </Authenticate>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
