import { PushPin } from "@mui/icons-material";
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import MailIcon from "@mui/icons-material/Mail";
import ArchivedIcon from "@mui/icons-material/Archive";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export default function SideBar({ open, tab, setTab }: any) {
  const list = () => (
    <Box sx={{ width: 250, paddingTop: "74px", position: "relative" }}>
      <List>
        {["Pinned Videos","Video", "Archived Videos", "Setting", "App Version"].map((text, index) => (
          
            <ListItemButton
              selected={text === tab}
              onClick={() => setTab(text)}
              key={text}
            >
              <ListItemIcon>
                {text === "Pinned Videos" && <PushPin/>}
                {text === "Video" && <VideoLibraryOutlinedIcon/>}
                {text === "Archived Videos" && <ArchivedIcon/>}
                {text === "Setting" && <InboxIcon />}
                {text === "App Version" && <MailIcon/>}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
        ))}
      </List>
      <Divider />
    </Box>
  );
  return (
    <>
      <Drawer
        anchor={"left"}
        open={open}
        variant="persistent"
      >
        {list()}
      </Drawer>
    </>
  );
}
