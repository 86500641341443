import axios from "axios";
import { getToken } from "./user";

export const updateCache = async () => {
  const res = await axios.get(`/api1/webhooks/webhooks/updateCache`, {
    headers: {
      Authorization: `Bearer ${getToken().access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return res.data;
};


export const searchCache = async () => {
  const res = await axios.get(`/api1/webhooks/webhooks/updateSearchCache`, {
    headers: {
      Authorization: `Bearer ${getToken().access_token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return res.data;
};
