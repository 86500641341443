import {
  AddCircle,
  CloudUpload,
  PushPin,
  Edit,
  Visibility,
  ContentCopy,
  DeleteOutline,
  PushPinOutlined,
} from "@mui/icons-material";
import ReactHlsPlayer from "react-hls-player";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useVideoSection from "./VideoSection.hooks";
import { changeVideoStatus } from "../../../../services/video";
import { useState } from "react";

const ArchivedVideoSection = () => {
  const {
    videoList,
    totalPage,
    currentPage,
    isVertical,
    openModel,
    loading,
    isEdit,
    handlePageChange,
    onEdit,
    editVideo,
    deleteVideo,
    archiveVideos,
    setModel,
    clearModalData,
    setUserIsVertical,
    description,
    title,
    categories,
    authorID,
    setUserData,
    authors,
    file,
    handleFileChange,
    setUserCategory,
    onModalDataSubmit,
    selectedCategory,
    keywords,
    isFeatured,
    setIsFeatured,
    deleteLoading,
    sponsoredLink,
    isDeepLink,
    paginationLoading,
    searchTitle,
    handleChangeSearchTitle,
    handleSubmitSearch,
    searchLoading,
    setUserIsDeepLink,
    showData,
    seeMoreModal,
    closeSeeMoreModal,
    videoUrl,
    playerRef,
    size,
    videoData,
    copyToClipboard,
    pinVideoById,
    unpinVideoById,
  } = useVideoSection();

  const actionButtonStyle = {
    borderRadius: 50,
    minWidth: 30,
    minHeight: 30,
    // backgroundColor: "red",
    padding: 0,
  };

  const ActionItemsList = ({ data }: { data: any }) => {
    console.log("data", data);

    return (
      <Grid
        container
        spacing={3}
        direction={"row"}
        // bgcolor={"#f2f2f2"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingRight={2}
      >
        <Grid item xs={3}>
          <Tooltip title={!data?.pinPosition ? "Pin" : "Unpin"}>
            <Button
              color="error"
              sx={actionButtonStyle}
              onClick={() => {
                if (data?.pinPosition) {
                  return unpinVideoById(data.id);
                } else pinVideoById(data.id);
              }}
            >
              {data?.pinPosition ? (
                <PushPin style={{ width: 18 }} color={"error"} />
              ) : (
                <PushPinOutlined style={{ width: 18 }} />
              )}
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Copy">
            <Button
              color="success"
              onClick={() => copyToClipboard(data)}
              sx={actionButtonStyle}
              size="small"
            >
              <ContentCopy fontSize={"small"} style={{ width: 16 }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Edit">
            <Button
              color="primary"
              sx={actionButtonStyle}
              onClick={() => editVideo(data)}
            >
              <Edit style={{ width: 18 }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <Tooltip title="Delete">
            <Button
              color="error"
              sx={actionButtonStyle}
              onClick={() => deleteVideo(data.id)}
            >
              <DeleteOutline style={{ width: 18 }} />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const [selected, setSelected] = useState(new Set());

  const handleSelectAllClick = (event:React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(new Set(videoList.map((row:any) => row.id))); // Replace `name` with a unique identifier
    } else {
      setSelected(new Set());
    }
  };

  const handleRowClick = (event:React.ChangeEvent<HTMLInputElement>, id:any) => {
    const newSelected = new Set(selected);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }
    setSelected(newSelected);
  };

  const handleRestore = () => {
    // Handle action on selected rows
    console.log('Selected rows:', Array.from(selected));
    if(!selected) return;
    // @ts-ignore
    archiveVideos(Array.from(selected), 'COMPLETED')
  };

  const isSelected = (id:any) => selected.has(id);


  return (
    <Container maxWidth="xl" sx={{ padding: 8, pt: 1 }}>
      <Button
        sx={{ marginTop: 2, marginBottom: 5 }}
        startIcon={<AddCircle />}
        variant="contained"
        color={"primary"}
        onClick={() => {
          setModel();
          clearModalData();
        }}
      >
        Add Video
      </Button>

      <Dialog
        sx={{
          pointerEvents: loading ? "none" : "auto",
          pt: 8,
        }}
        open={seeMoreModal}
        onClose={closeSeeMoreModal}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container rowSpacing={2} pt={1}>
            <Grid item xs={6}>
              <Typography variant="body1">Title</Typography>
            </Grid>
            <Grid item xs={6}>
              {title}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Sponsored Link</Typography>
            </Grid>
            <Grid item xs={6}>
              {sponsoredLink || "N/A"}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Categories</Typography>
            </Grid>
            <Grid item xs={6}>
              {selectedCategory}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Keywords</Typography>
            </Grid>
            <Grid item xs={6}>
              {keywords}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Description</Typography>
            </Grid>
            <Grid item xs={6}>
              {description}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Is Vertical</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {isVertical ? isVertical.toString() : "false"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Is Featured</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {isFeatured ? isFeatured.toString() : "false"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1"> Is Deeplink</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">
                {isDeepLink ? isDeepLink.toString() : "false"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Video URL</Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                startIcon={<ContentCopy />}
                color={"success"}
                onClick={() => copyToClipboard(videoUrl)}
              >
                Copy Link
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1">Video</Typography>
            </Grid>
            <Grid item xs={6}>
              <ReactHlsPlayer
                src={videoUrl}
                playerRef={playerRef}
                autoPlay={false}
                controls={true}
                width="60%"
                height="auto"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          pointerEvents: loading ? "none" : "auto",
          pt: 8,
        }}
        open={openModel}
        onClose={setModel}
        fullWidth
      >
        <DialogTitle sx={{ marginTop: 3 }}>{"Add Video details"}</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={2} pt={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                placeholder="Enter the title"
                value={title}
                onChange={(e: any) => setUserData(e, "title")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Sponsored link"
                placeholder="Enter the sponsored link"
                value={sponsoredLink}
                onChange={(e: any) => setUserData(e, "sponsoredLink")}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={categories}
                value={selectedCategory}
                onChange={(e, value) => {
                  setUserCategory(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categories"
                    placeholder="Select the categories"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                rows={4}
                label="Keywords"
                placeholder="Type keywords"
                value={keywords || ""}
                onChange={(e: any) => setUserData(e, "keywords")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                fullWidth
                rows={4}
                label="Description"
                placeholder="Write the description"
                value={description}
                onChange={(e: any) => setUserData(e, "description")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pr: 2 }}>
                  Is Vertical
                </FormLabel>
                <RadioGroup
                  aria-label="vertical"
                  name="vertical"
                  value={isVertical}
                  onChange={(e: any) => {
                    setUserIsVertical(e.target.value === "true" ? true : false);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pr: 2 }}>
                  Is Featured
                </FormLabel>
                <RadioGroup
                  aria-label="isFeatured"
                  name="isFeatured"
                  value={isFeatured}
                  onChange={(e: any) => {
                    setIsFeatured(e.target.value === "true" ? true : false);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel component="legend" sx={{ pr: 2 }}>
                  Is Deeplink
                </FormLabel>
                <RadioGroup
                  aria-label="Is Deep link"
                  name="Is Deep link"
                  value={isDeepLink}
                  onChange={(e: any) => {
                    setUserIsDeepLink(e.target.value === "true" ? true : false);
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label="True"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label="False"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="select-for-author">Author ID</InputLabel>
                <Select
                  labelId="select-for-author"
                  id="select-for-author"
                  value={authorID}
                  label="Select the author type"
                  onChange={(e: any) => {
                    setUserData(e, "authorID");
                  }}
                >
                  {authors &&
                    authors.map((item: { id: string; name: string }) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div>
                <input
                  accept="video/*"
                  style={{
                    display: "none",
                  }}
                  disabled={loading}
                  id="video-upload"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="video-upload">
                  <Box
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "250px",
                        border: "blue dotted 0.7px",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <CloudUpload
                        sx={{
                          fontSize: "120px",
                        }}
                      />
                      <Typography variant="h5">Upload Video</Typography>

                      {file && videoData && (
                        <Typography variant="h6" fontWeight={"bold"}>
                          {file}
                        </Typography>
                      )}
                      {file && videoData && (
                        <Typography variant="h6" fontWeight={"bold"}>
                          {`${size}`}
                        </Typography>
                      )}

                      {file && !videoData && (
                        <Typography
                          variant="h6"
                          fontWeight={"bold"}
                          color={"red"}
                        >
                          {file}
                        </Typography>
                      )}
                      {file && !videoData && (
                        <Typography
                          variant="h6"
                          fontWeight={"bold"}
                          color={"red"}
                        >
                          {`${size}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </label>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                disabled={loading}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (!isEdit) {
                    onModalDataSubmit();
                  } else {
                    onEdit();
                  }
                }}
              >
                <Typography variant="h6" textAlign={"center"}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {isEdit ? "Update" : "Submit"}
                    {loading && (
                      <CircularProgress
                        color="info"
                        sx={{
                          width: "26px !important",
                          height: "26px !important",
                          px: 2,
                          color: "white",
                        }}
                      />
                    )}{" "}
                  </Box>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          color: "black",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          py: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleRestore}
          disabled={selected.size === 0}
        >
          Restore
        </Button>
        <TextField
          sx={{
            pr: 2,
            width: "450px",
          }}
          size="small"
          placeholder="Search title here"
          value={searchTitle}
          id="searchText"
          onChange={handleChangeSearchTitle}
          onKeyDown={handleSubmitSearch}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          minHeight: "610px",
        }}
      >
        {videoList === null && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Typography variant="h4" component="h1" align="center">
              No Records Found
            </Typography>
            <LoadingButton
              loading={paginationLoading}
              disabled={paginationLoading}
              variant="contained"
              sx={{ mt: 4 }}
              color="primary"
              onClick={(e: any) => {
                handlePageChange(e, currentPage);
              }}
            >
              Reload
            </LoadingButton>
          </Box>
        )}
        {searchLoading && <LinearProgress />}
        {paginationLoading && <LinearProgress />}
        {deleteLoading && <LinearProgress />}
        {videoList && videoList.length <= 0 && <LinearProgress />}
        {videoList && videoList.length > 0 && (
          <Table
            sx={{ minWidth: 650, marginTop: 2, marginBottom: 5 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected.size === videoList.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}></Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Title
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Description
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Is Featured
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Is Vertical
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Author Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Video Category
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Actions
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {videoList && videoList.length > 0 && (
              <TableBody>
                {videoList &&
                  videoList.map((row: any) => {
                    const isItemSelected = isSelected(row.id); // Replace `name` with a unique identifier
                    return (
                      <TableRow
                        key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleRowClick(event, row.id)} // Replace `name` with a unique identifier
                            inputProps={{ 'aria-labelledby': row.id }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="see more"
                            color="primary"
                            onClick={() => {
                              showData(row);
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>{`${row.description?.substr(0, 150)} ${row.description.length > 150 ? "..." : ""
                          }`}</TableCell>
                        <TableCell>
                          <Chip
                            label={row.is_featured.toString()}
                            color={row.is_featured ? "success" : "error"}
                            variant="filled"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={row.isVertical.toString()}
                            color={row.isVertical ? "success" : "error"}
                            variant="filled"
                            size="small"
                          />
                        </TableCell>
                        <TableCell>
                          {(row.author && row.author.name) || "N/A"}
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>
                          {row.video_categories &&
                            row.video_categories
                              .slice(0, 2)
                              .map((item: any, index: number) => {
                                return (
                                  <>
                                    <Typography variant="body1">{`${item}`}</Typography>
                                  </>
                                );
                              })}
                          {row.video_categories.length > 2 ? "..." : ""}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <ActionItemsList data={row} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            )}
          </Table>
        )}
      </TableContainer>
      {videoList && videoList.length > 0 && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={totalPage}
            page={currentPage}
            onChange={handlePageChange}
            hidePrevButton={false}
            hideNextButton={false}
          />
        </Box>
      )}
    </Container>
  );
};

export default ArchivedVideoSection;
