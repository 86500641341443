import { LoadingButton } from "@mui/lab";
import { Box, Grid, Typography, Input } from "@mui/material";
import TextField from "@mui/material/TextField";
import useAdminLoginPage from "./AdminLoginPage.hooks";

const AdminLoginPage = () => {
  const { email, password, loading, onLogin, changeData } = useAdminLoginPage();

  return (
    <Box
      sx={{
        height: "78vh",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid container maxWidth={"sm"} spacing={4}>
        <Grid item xs={12} textAlign={"center"}>
          <Box py={20} pb={12}>
            <Typography fontWeight={"800"} variant="h2">
              Kiindred Admin Login
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            placeholder="Enter the email"
            value={email}
            onChange={(e: any) => changeData(e, "email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            placeholder="Enter the password"
            onChange={(e: any) => changeData(e, "password")}
          />
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          <LoadingButton
            sx={{
              width: "40%",
              py: 1,
            }}
            loading={loading}
            variant="contained"
            color="primary"
            onClick={onLogin}
          >
            <Typography variant="body1">Login</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AdminLoginPage;
